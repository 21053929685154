import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sublist", "internalCommunication", "whitelistEmail", "whitelistDomains", "prelaunchTasksMaster"];

  connect() {
    console.log("connected");
  }

  togglePreLaunchTasks(e) {
    e.preventDefault();
    this.sublistTarget.classList.toggle("hidden");
  }

  toggleInternalCommunication(e) {
    e.preventDefault();
    this.internalCommunicationTarget.classList.toggle("complete");
    this.internalCommunicationTarget.classList.toggle("incomplete");

    this.backendToggle('toggle_sent_internal_communication');
  }

  toggleWhitelistEmail(e) {
    e.preventDefault();
    this.whitelistEmailTarget.classList.toggle("complete");
    this.whitelistEmailTarget.classList.toggle("incomplete");

    this.backendToggle('toggle_whitelist_email');
  }

  toggleWhitelistDomains(e) {
    e.preventDefault();
    this.whitelistDomainsTarget.classList.toggle("complete");
    this.whitelistDomainsTarget.classList.toggle("incomplete");

    this.backendToggle('toggle_whitelist_domains');
  }

  completedAllPrelaunchTasks() {
    if (this.internalCommunicationTarget.classList.contains("complete") &&
      this.whitelistEmailTarget.classList.contains("complete") &&
      this.whitelistDomainsTarget.classList.contains("complete")) {
      if (this.prelaunchTasksMasterTarget.classList.contains("incomplete")) {
        this.prelaunchTasksMasterTarget.classList.toggle("complete");
        this.prelaunchTasksMasterTarget.classList.toggle("incomplete");
      }
    } else {
      if (this.prelaunchTasksMasterTarget.classList.contains("complete")) {
        this.prelaunchTasksMasterTarget.classList.toggle("complete");
        this.prelaunchTasksMasterTarget.classList.toggle("incomplete");
      }
    }

  }

  backendToggle(endpoint) {
    const url = `/registrations/${this.element.dataset.registrationId}/${endpoint}`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(_data => this.completedAllPrelaunchTasks())
      .catch(error => console.error("Error:", error));
  }
}
